<template>
  <div class="activation-background" :class="activeSection">
    <div />
    <div />
  </div>
</template>

<script>
export default {
  name: 'ActivationBackground',
  props: {
    activeSection: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .activation-background {
    background-color: #C8E8F5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    div {
      &:first-child, &:last-child {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 1s;
      }
      &:first-child {
        background: url('~@/assets/img/activation/bg-hero.png') center / cover no-repeat;
      }
      &:last-child {
        background: url('~@/assets/img/activation/bg-activation.png') center / cover no-repeat;
      }
    }
    &.hero, &.company, &.select, &.form {
      div {
        transform: scale(1.2);
      }
    }
    &.hero {
      div {
        &:first-child {
          opacity: 1;
        }
      }
    }
    &.company, &.select, &.form {
      div {
        transform: none;
        &:last-child {
          opacity: 1;
        }
      }
    }
  }
</style>