<template>
  <section class="renewal-page-form">
    <div class="container">
      <form class="form">
        <div class="wrapper-fields mb-xl">
          <p class="fs--medium fw--bold">
            Продление обслуживания
          </p>
          <div class="divider" />
          <p class="mb-m">
            Укажите Ваши данные.
          </p>
          <input-text class="mb-s" placeholder="Имя" />
          <input-text class="mb-s" placeholder="Телефон" />
          <input-text class="mb-s" placeholder="Уникальный номер" />
        </div>
        <div class="wrapper-submit mb-xl">
          <input-checkbox v-model="checked" class="mb-l" name="acception">
            Я принимаю условия&nbsp;
            <router-link class="red-text td--underline" to="/privacy-policy">
              Соглашения
            </router-link>
          </input-checkbox>
          <app-button
            class="btn-submit"
            title="Проверить"
            description="Активация, продления, смена держателя"
            primary
          >
            <icon-debug class="icon" />
          </app-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import InputCheckbox from '@/components/input/InputCheckbox'
import InputText from '@/components/input/InputText'
import IconDebug from '@/assets/img/icons/debug.svg'

export default {
  name: 'RenewalPageForm',
  components: {
    AppButton,
    InputCheckbox,
    InputText,
    IconDebug
  },
  data () {
    return {
      checked: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .renewal-page-form {
    .container {
      min-height: 100vh;
    }
    .form {
      width: 100%;
      max-width: 864px;
      .wrapper-fields {
        display: flex;
        flex-direction: column;
      }
      .wrapper-submit {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include max-w-sm {
          flex-direction: column;
        }
        .btn-submit {
          max-width: 300px;
          .icon {
            path {
              stroke: $white;
              fill: $white;
            }
          }
        }
      }
    }
  }
</style>